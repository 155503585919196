const InstitutionList = {
  REF: 'Rungta Educational Foundation',
  RCET: 'Rungta College of Engineering & Technology' ,
  GDRCST: 'GD Rungta College of Science & Technology',
  RIPER: 'Rungta Institute Of Pharmaceutical Education & Research',
  RCPSR: 'Rungta College Of Pharmaceutical Science & Research',
  RSDC: 'Rungta Student Development Cell',
  CDC: "Career Development Center",
  CRC: "Central Research Committee",
  RUBI: "Rungta Business Incubator",
  // Add more institution mappings as needed
};

const DepartmentList = {
  //RCET
  CSE: 'Computer Science & Engineering',
  AI: 'Computer Science - Artifical Intelligence',
  ML: 'Computer Science - Artificial Intelligence and Machine Learning',
  DS: 'Computer Science - Data Science',
  CyberSec: 'Computer Science - Cyber Security',
  IT: 'Information Technology',
  CE: 'Civil Engineering',
  AE: 'Agricultural Engineering',
  Automobile: 'Automobile Engineering',
  EE: 'Electrical Engineering',
  EEE: 'Electrical & Electronics Engineering',
  ETC: 'Electronics & Telecommunication',
  ME: 'Mechanical Engineering',
  Mining: 'Mining Engineering',
  DiplomaCE: 'Diploma - Civil Engineering',
  DiplomaME: 'Diploma - Mechanical Engineering',
  MCA: 'Master of Computer Application (MCA)',
  MBA: 'Master of Business Administration (MBA)',
  MTechCE: 'M.Tech. - Civil Engineering',
  MTechCSE: 'M.Tech. - Computer Science & Engineering',
  PhDChem: 'Ph. D. - Applied Chemistry',
  PhDMath: 'Ph. D. - Applied Mathematics',
  PhDPhysics: 'Ph. D. - Applied Physics',
  PhDCompApp: 'Ph. D. - Computer Application',
  PhDCompSci: 'Ph. D. - Computer Science & Engineering',
  PhDEE: 'Ph. D. - Electrical Engineering',
  PhDElectronics: 'Ph. D. - Electronics Engineering',
  PhDHumanities: 'Ph. D. - Humanities',
  PhDManagement: 'Ph. D. - Management',
  PhDME: 'Ph. D. - Mechanical Engineering',
  BCom: 'Bachelor of Commerce (B.Com - Computer Applications)',
  BBA: 'Bachelor of Business Administration (BBA)',
  BCA: 'Bachelor of Computer Application (BCA)',
  BScBiotech: 'B.Sc. (Biotechnology - Zoology/Botany)',
  BScCS: 'B.Sc. (Computer Science)',
  BScPCM: 'B.Sc. (PCM / Maths)',
  MScBiotech: 'M.Sc. (Biotechnology)',
  MSW: 'Master of Social Work (MSW)',
  PGDCA: 'PGDCA',
  DEd: 'Diploma in Education (D. Ed.)',
  BEd: 'Bachelor of Education (B. Ed.)',
  MEd: 'Master of Education(M. Ed.)',
  BA: 'Bachelor of Arts (B. A.)',
  IC: 'Infinity Coders Club',
  RM: 'Rangmanch Club',
  RAG: 'Rungta Art Gallery Club',
  RV: 'Runvijay Club',
  BEA: 'Beacon Club',
  COPE: 'COPE Club',
  GDSC: 'Google Developer Student Club',
  CP: 'Carpediem Club',
  ES: 'E-Sports Club',
  RAISE: 'Raise Club',
  RADI: 'Radiance Club',
  RAD: 'Radiction Club',
  TM: 'Toastmaster Club',
  XCELLA: 'Xcella Club',
  RC: 'R-Connect Club',
  RISE:'Rise Club',
  CDC: "Career Development Center",
  CRC: "Central Research Committee",
  RUBI: "Rungta Business Incubator",



  ECS: 'ECS',
  Admin: 'Management',
  
  // GDRCST
  BCom: 'Bachelor of Commerce (B.Com - Computer Applications)',
  BBA: 'Bachelor of Business Administration (BBA)',
  BCA: 'Bachelor of Computer Application (BCA)',
  BScBio: 'B.Sc. (Biotechnology - Zoology/Botany)',
  BScCS: 'B.Sc. (Computer Science)',
  BScPCM: 'B.Sc. (PCM / Maths)',
  MScBio: 'M.Sc. (Biotechnology)',
  MSW: 'Master of Social Work (MSW)',
  PGDCA: 'PGDCA',
  DEd: 'Diploma in Education (D. Ed.)',
  BEd: 'Bachelor of Education (B. Ed.)',
  MEd: 'Master of Education(M. Ed.)',
  BA: 'Bachelor of Arts (B. A.)',
 

  //RIPER
  DPharmacy: 'Diploma in Pharmacy (D. Pharmacy)',
  BPharmacy:'Bachelor of Pharmacy (B.Pharmacy)',
  

  //RCPSR
  DPharmacy: 'Diploma in Pharmacy (D. Pharmacy)',
  BPharmacy: 'Bachelor of Pharmacy (B.Pharmacy)',
  MPharmPharmacology: 'M. Pharmacy - Pharmacology',
  MPharmPharmaceutics: 'M. Pharmacy - Pharmaceutics',
  PhD: 'Ph. D.',
 

  //RSDC

};

const institutions = [
  {
    name: InstitutionList['RCET'],
    departments: [
      DepartmentList['CSE'],
      DepartmentList['AI'],
      DepartmentList['ML'],
      DepartmentList['DS'],
      DepartmentList['CyberSec'],
      DepartmentList['IT'],
      DepartmentList['CE'],
      DepartmentList['AE'],
      DepartmentList['Automobile'],
      DepartmentList['EE'],
      DepartmentList['EEE'],
      DepartmentList['ETC'],
      DepartmentList['ME'],
      DepartmentList['Mining'],
      DepartmentList['DiplomaCE'],
      DepartmentList['DiplomaME'],
      DepartmentList['MCA'],
      DepartmentList['MBA'],
      DepartmentList['MTechCE'],
      DepartmentList['MTechCSE'],
      DepartmentList['PhDChem'],
      DepartmentList['PhDMath'],
      DepartmentList['PhDPhysics'],
      DepartmentList['PhDCompApp'],
      DepartmentList['PhDCompSci'],
      DepartmentList['PhDEE'],
      DepartmentList['PhDElectronics'],
      DepartmentList['PhDHumanities'],
      DepartmentList['PhDManagement'],
      DepartmentList['PhDME'],
      
    ],
  },
  {
    name: InstitutionList['GDRCST'],
    departments: [
      DepartmentList['BCom'],
      DepartmentList['BBA'],
      DepartmentList['BCA'],
      DepartmentList['BScBiotech'],
      DepartmentList['BScCS'],
      DepartmentList['BScPCM'],
      DepartmentList['MScBiotech'],
      DepartmentList['MSW'],
      DepartmentList['PGDCA'],
      DepartmentList['DEd'],
      DepartmentList['BEd'],
      DepartmentList['MEd'],
      DepartmentList['BA'],
      
    ],
  },
  {
    name: InstitutionList['RIPER'],
    departments: [
      DepartmentList['DPharmacy'],
      DepartmentList['BPharmacy'],
    ],
  },
  {
    name: InstitutionList['RCPSR'],
    departments: [
      DepartmentList['DPharmacy'],
      DepartmentList['BPharmacy'],
      DepartmentList['MPharmPharmacology'],
      DepartmentList['MPharmPharmaceutics'],
      DepartmentList['PhD'],
      
    ],
  },
  {
    name: InstitutionList['REF'],
    departments: [
      DepartmentList['Management'],
      DepartmentList['ECS'],
     
      
    ],
  },
  {
    name: InstitutionList['RSDC'],
    departments: [
      DepartmentList['IC'],
      DepartmentList['RM'],
      DepartmentList['RAG'],
      DepartmentList['RV'],
      DepartmentList['RISE'],
      DepartmentList['RAISE'],
      DepartmentList['RADI'],
      DepartmentList['RUBI'],
      DepartmentList['RAD'],
      DepartmentList['RC'],
      DepartmentList['CP'],
      DepartmentList['TM'],
      DepartmentList['XCELLA'],
      DepartmentList['ES'],
      DepartmentList['COPE'],
      DepartmentList['GDSC'],
      DepartmentList['BEA'],
    ],
  }
];

export { institutions, InstitutionList, DepartmentList };
